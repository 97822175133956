
import { useRef } from "react"
import MostrarseyCae from '../componentes/SEyCAE'
import { useAspiranteStore, useDataSelectStore } from "../hooks";
import { Button, Col, Row } from "antd";
import { useNavigate } from "react-router-dom";
import CarruselSECAE from "../componentes/CarruselSECAE";
import { ModalMessage } from "../componentes/ModalMessage";
import * as etiquetas from '../componentes/ApplicationResources';

export const Aspirantes = () => {
    const { listaAspirantes, startCleanAspirantes } = useAspiranteStore();
    const { datosProcesos, startCleanDataSelect } = useDataSelectStore();
    const resultsDiv = useRef()
    const navigate = useNavigate();



    const handleReturn = () => {
        console.log('click')
        startCleanAspirantes();
        startCleanDataSelect()
        navigate('/', { replace: true });
    }

    return (
        <div className="busqueda">


            {
                listaAspirantes !== undefined && listaAspirantes.length > 0
                    ?

                    <div>


                        <div className="contenido">
                            <div style={{ display: 'grid', placeItems: 'center', textAlign: 'center' }}>
                                <h1>Lista de Supervisores Electorales y Capacitadores Asistentes Electorales</h1>

                            </div>
                            <p>Resultados encontrados: {listaAspirantes.length}</p>
                            <div className="linea" style={{ backgroundColor: '#D992C8' }}></div>
                            <Row>
                                <Col span={8}>
                                    <h2 style={{ color: '#D5007F' }}>Sección: {datosProcesos.seccion}</h2>
                                </Col>
                                <Col span={8} offset={8}>
                                    <div className="buscaSEyCAEBoton">
                                        <Button
                                            type="primary"
                                            onClick={handleReturn}>Nueva búsqueda
                                        </Button>

                                    </div>
                                </Col>
                            </Row>

                            {
                                listaAspirantes.length !== 0 && listaAspirantes.length >= 3
                                    ?
                                    <CarruselSECAE />
                                    :
                                    <Row
                                        ref={resultsDiv}
                                        justify="space-evenly"
                                        style={{ width: 'auto' }}>
                                        {
                                            listaAspirantes && listaAspirantes.map(aspirante => (
                                                <Col className="gutter-row" >
                                                    <MostrarseyCae
                                                        key={aspirante.idAspirante}
                                                        aspirante={aspirante}
                                                        idAspirante='unico'
                                                    />
                                                </Col>

                                            ))
                                        }
                                    </Row>
                            }
                        </div>
                    </div>
                    :
                    <ModalMessage
                        mensaje={etiquetas
                            .mensajeNoEncontroSEyCAE}
                        tipoMensaje={1}
                    />
            }

        </div>
    )
}
