
import { ConfigProvider } from 'antd';
import esES from 'antd/es/locale/es_ES';

/*Componentes */
import globalVars from '../utils/globalVars';
import Bienvenido from './Bienvenido';
import { InformacionAspirante } from '../pages';
import { useDataSelectStore, useUIStore } from '../hooks';

const Template = ({ isVerified }) => {

    //     this.onChangeRadioEncuesta = this.onChangeRadioEncuesta.bind(this);
    const { datosProcesos } = useDataSelectStore();
    const { isLoading } = useUIStore()
    const { idProceso,
        idDetalleProceso,
        idParticipacion,
        idAreaResponsabilidad,
        idAspirante } = datosProcesos;

    const validarEnteros = (valor) => {
        let valorCorrecto = undefined;
        let regex = /^[0-9]+$/;
        if (valor !== undefined && valor !== null) {
            valor = valor.trim();
            let valorEntero = parseInt(valor);
            if (regex.test(valorEntero)) {
                valorCorrecto = valorEntero
            }
        }
        return valorCorrecto;
    }








    // const onChangeRadioEncuesta = (e) => {
    //     try {
    //         console.log("onEn " + isVerified)
    //         if (isVerified) {
    //             let resultado = 0;
    //             if (e.target.value !== undefined && e.target.value !== '') {
    //                 let jSonData = {
    //                     "idProcesoElectoral": globalVars.uiText.idProceso,
    //                     "idDetalleProceso": globalVars.uiText.idDetalle,
    //                     "idParticipacion": globalVars.uiText.idParticipacion,
    //                     "informacionUtil": e.target.value === 'SI' ? 0 : 1,
    //                 }
    //                 let url = process.env.REACT_APP_API_URL + '/guardarEncuesta';
    //                 axios.post(
    //                     url,
    //                     jSonData,
    //                     {
    //                         headers: {
    //                             'Accept': 'application/json',
    //                             'Content-Type': 'application/json'
    //                         }
    //                     }
    //                 ).then(res => {
    //                     const code = res.data.code;
    //                     if (code && code === 200) {
    //                         resultado = 1;
    //                     } else if (code && code === 1) {
    //                         resultado = 1;
    //                     }
    //                     this.setState({ sitio: e.target.value, radioEncuesta: resultado });
    //                 }).catch(error => {
    //                     console.error('mssg:Error consumingEncuesta:', error);
    //                     this.setState({ sitio: e.target.value, radioEncuesta: resultado });
    //                 });
    //             }
    //             this.setState({ sitio: e.target.value });
    //         }
    //     } catch (error) {
    //         console.log("Error en el método onChangeRadio " + error);
    //     }
    // }

    //console.log( "cargando en template "+this.props.cargando)
    let deshabilidarEncuesta = true;

    if (globalVars.uiText.idProceso !== undefined && globalVars.uiText.idProceso !== 0 && globalVars.uiText.idProceso !== '0' &&
        globalVars.uiText.idDetalle !== undefined && globalVars.uiText.idDetalle !== 0 && globalVars.uiText.idDetalle !== '0' &&
        globalVars.uiText.idParticipacion !== undefined && globalVars.uiText.idParticipacion !== 0 && globalVars.uiText.idParticipacion !== '0') {
        deshabilidarEncuesta = false;
    }
    //console.log("deshabilidarEncuesta "+ deshabilidarEncuesta +" d "+this.state.radioEncuesta)
    if (deshabilidarEncuesta === false && this.state.radioEncuesta !== undefined && this.state.radioEncuesta === 1) {
        deshabilidarEncuesta = true;
    }
    //console.log("deshabilidarEncuesta "+ deshabilidarEncuesta +" d "+this.state.radioEncuesta)




    return (
        <ConfigProvider locale={esES}>
            {
                isVerified //captchaVerificado
                    ?
                    idProceso !== '0' && idDetalleProceso !== '0' && idParticipacion !== '0' &&
                        (idAspirante !== '0' || idAreaResponsabilidad !== '0')
                        && idProceso !== 0 && idDetalleProceso !== 0
                        && idParticipacion !== 0 &&
                        (idAspirante !== 0 || idAreaResponsabilidad !== 0)
                        ?
                        <InformacionAspirante
                            cargando={true}
                            datosProcesos={datosProcesos}
                            isVerified={isVerified}
                        />
                        :
                        <Bienvenido
                            cargando={isLoading}
                            isVerified={isVerified}
                        >
                        </Bienvenido>
                    :
                    <h1 key="h1"> Hola mundo</h1>
            }
        </ConfigProvider >
    );
}
export default Template;
