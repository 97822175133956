/*React */
import React, { useState } from "react";
import { Row, Col, Button, Image } from 'antd';

import bienvenido from "../img/Bienvenido_Conoce_CAE.svg";
import { BuscaSEyCAE } from '../pages';

const Bienvenido = ({ isVerified }) => {

    const [mostrarBienvenida, setMostrarBienvenida] = useState(false)


    const onClickBienvenido = (e) => {
        console.log("bienvenido ");
        setMostrarBienvenida(false);
    }

    return (

        <>
            {
                mostrarBienvenida
                    ?
                    <div className="contenido">
                        <div className="bienvenido">
                            <Row className="titleBienvenido">
                                <Col xs={24} sm={24} md={24} lg={24} >
                                    <b>Bienvenido a Conoce a tu SE y CAE</b>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} >
                                    <div >
                                        <Image src={bienvenido} preview={false} />
                                    </div>
                                </Col>
                            </Row>
                            <Row className="butonBusqueda">
                                <Col xs={24} sm={24} md={24} lg={24} >
                                    <div >
                                        <Button type="primary" onClick={onClickBienvenido}>Búsqueda de SE y CAE</Button>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} >
                                </Col>
                            </Row>
                        </div>
                    </div>
                    :
                    <BuscaSEyCAE isVerified={isVerified} key="buscaSECae" />
            }
        </>

    );
}
export default Bienvenido;
