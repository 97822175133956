/*React */
import React, { useEffect, useState } from "react";
import MostrarseyCae from '../componentes/SEyCAE';
import CarruselSECAE from "../componentes/CarruselSECAE";
import { Row, Col, Button } from 'antd';

/*Componentes */
import * as etiquetas from '../componentes/ApplicationResources';
import globalVars from '../utils/globalVars';
import { useAspiranteStore, useDataSelectStore } from "../hooks";
import { ModalMessage } from "../componentes/ModalMessage";
import { useNavigate } from "react-router-dom";

/*Clase que se carga cuando se obtienen datos del QR del gafete o de la carta notificación*/
export const InformacionAspirante = ({
    cargando,
    isVerified }) => {
    const { datosProcesos, startCleanDataSelect } = useDataSelectStore();

    const { startLoadingAspirantebySeccion, startCleanAspirantes, listaAspirantes } = useAspiranteStore();
    const navigate = useNavigate();

    const { idProceso,
        idDetalleProceso,
        idParticipacion,
        idAspirante,
        idAreaResponsabilidad, } = datosProcesos

    const [loading, setLoading] = useState(cargando);
    const [labora, setLabora] = useState(false);
    const [seccionInfoAspirante, setSeccionInfoAspirante] = useState(0);

    const handleReturn = () => {
        console.log('click')
        startCleanAspirantes();
        startCleanDataSelect();
        navigate('/', { replace: true });
    }




    const actualizarSeccion = (seccionAspirante) =>{
      setSeccionInfoAspirante(seccionAspirante)
    }

    useEffect(() => {
        obtenerInformacionAspirante();
    }, [])

    useEffect(() => {
        if (listaAspirantes.length > 0) {
            validarAspiranteLabora();
        }

    }, [listaAspirantes])

    const onChangeLoading = (value) => {
        onChangeLoading([
            cargando
        ]);
    }

    const obtenerInformacionAspirante = () => {
        console.log("info Aspirante " + isVerified);
        if (isVerified) {
            actualizarVariables();
            startLoadingAspirantebySeccion()
            // startLoadingAspirantes(datosProcesos);
        }
    }

    const actualizarVariables = () => {
        globalVars.uiText.idProceso = idProceso;
        globalVars.uiText.idDetalle = idDetalleProceso;
        globalVars.uiText.idParticipacion = idParticipacion;
    }

    const validarAspiranteLabora = () => {
        let labora = true;
        if (idAspirante !== undefined && idAspirante !== '0') {
            let aspirante = listaAspirantes[0];
            console.log("aspirante " + aspirante.estatus)
            if (aspirante.estatus !== null && aspirante.estatus !== 'A' && aspirante.estatus !== 'a') {
                setLabora(false);
            }
            setLabora(true)
        }
        return labora;
    }




    //mostrar info aspirante
    return (
        <div className="busqueda">


            <div>
                {
                    listaAspirantes !== undefined && listaAspirantes.length > 0
                        ?

                        labora
                            ?
                            <div className="contenido">
                                <div className="container-title">
                                    <h1>Lista de Supervisores Electorales y Capacitadores Asistentes Electorales</h1>

                                </div>
                                <p>Resultados encontrados: {listaAspirantes.length}</p>
                                <div className="linea" style={{ backgroundColor: '#D992C8' }}></div>
                                <Row>
                                    <Col lg={{ span: 8 }} xs={{ span: 8 }}>
                                        <h2 className="subtitle" >
                                         {seccionInfoAspirante !=="" ? "Secciones :"+seccionInfoAspirante
                                          : "Sección :"+datosProcesos.seccion}
                                        </h2>
                                    </Col>
                                    <Col lg={{ offset: 8, span: 8 }} xs={{ span: 6, offset: 5 }}>
                                        <div className="buscaSEyCAEBoton">
                                            <Button
                                                type="primary"
                                                onClick={handleReturn}>Nueva búsqueda
                                            </Button>

                                        </div>
                                    </Col>
                                </Row>

                                {

                                    listaAspirantes !== undefined && listaAspirantes.length > 1
                                        ?
                                        <CarruselSECAE
                                            onChangeLoading={onChangeLoading}
                                            jsonAspirante={listaAspirantes}
                                        />
                                        :
                                        // mostrar si jsonAspirante.length es menor a 2
                                        <Row className="rowContentSEyCAE" justify="center">
                                            <Col key={"infoAspirante" + listaAspirantes[0].idAspirante}>
                                                <MostrarseyCae
                                                    onChangeLoading={onChangeLoading}
                                                    cargando={loading}
                                                    key={"infoAspirante" + listaAspirantes[0].idAspirante}
                                                    aspirante={listaAspirantes[0]}
                                                    idAspirante={idAspirante}
                                                    idAreaResponsabilidad={idAreaResponsabilidad}
                                                    actualizarSeccion={actualizarSeccion}
                                                />
                                            </Col>
                                        </Row>
                                }

                            </div>

                            :
                            <ModalMessage
                                mensaje={etiquetas.mensajeSEyCAENoLaboraINE}
                                tipoMensaje={2}
                            />
                        :
                        <ModalMessage
                            mensaje={etiquetas.mensajeNoEncontroSEyCAE}
                            tipoMensaje={1}
                        />
                }
            </div>

        </div>

    );
}
