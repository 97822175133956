//!TODO: convertir a hook
export const descifrarCadena = () => {
    const algorithm = 'aes-256-gcm'; 
    try {
        const password = process.env.REACT_APP_PUBLICADOR_KEY;
        let encrypted = this.props.location.pathname;
        encrypted = encrypted.replace("/conoceSEyCAE", "");
        encrypted = encrypted.replace("/access", "");
        encrypted = encrypted.substr(1);
        encrypted = encrypted.replace(/\$/g, "/");
        const buffer = Buffer.from(encrypted, 'base64');
        const iv = buffer.slice(0, 12);
        const salt = buffer.slice(12, 28);
        const tag = buffer.slice(buffer.length - 16, buffer.length);
        const data = buffer.slice(28, buffer.length - 16);
        const key = crypto.pbkdf2Sync(Buffer.from(password, "utf8"), salt, 65536, 32, 'sha256');
        let decipher = crypto.createDecipheriv(algorithm, key, iv);
        decipher.setAutoPadding(false);
        decipher.setAuthTag(tag);
        let dec = decipher.update(data, 'base64', 'utf-8');
        dec += decipher.final('utf-8');
        let json = JSON.parse(dec);
        return {isDecoded:true, jsonAspirante:json};
    } catch (error) {
        console.error("error al descifrar la cadena " + error);
        return {isDecoded:false,  jsonAspirante:{}};
    }
}