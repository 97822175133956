
import { Row, Col, Image, Button, Card } from 'antd';
import { usePuestoFotoAspirante } from '../hooks/useObtenerPuestoFoto'
import { ModalInfo } from "../componentes/ModalInfo";
import { useState } from 'react';
import { InfoCircleFilled } from '@ant-design/icons';
import { useEffect } from 'react';
import funcionDeUnCAE from '../img/funcion_de_un_CAE.png';
import funcionDeUnSE from '../img/funcion_de_un_SE.png';

export const SEyCAE = ({ aspirante, idAspirante,actualizarSeccion }) => {

    const [openModal, setOpenModal] = useState(false)

    const { nombrePuesto, fotoAspirante, claseFoto } = usePuestoFotoAspirante(aspirante);

    const [tipoAspirante, setTipoAspirante] = useState('')
    const [funcionesAspirante, setFuncionesAspirante] = useState([])

    useEffect(() => {
        if (aspirante.idPuesto !== 1) {
            setTipoAspirante('CAE');
            setFuncionesAspirante(funcionDeUnCAE);
        } else {
            setTipoAspirante('SE');
            setFuncionesAspirante(funcionDeUnSE);
        }
        console.info("info aspirante por url",aspirante);
        if (idAspirante > 0) {
          actualizarSeccion(aspirante.seccionCae !== "" ? aspirante.seccionCae :
            aspirante.seccionSe);
        }
    }, [aspirante.id])

    const handelOpenModal = () => {
        setOpenModal(true);
    }

    return (
        <>
            <Card className={"contenidoSEyCAE"}>
                {/* <div className={idAspirante === 'unico' ? 'catUnico' : "cat"}> */}
                <Image src={fotoAspirante}
                    preview={false}
                    id={idAspirante === 'unico' ? 'imgFotoSEyCAEUnico' : 'imgFotoSEyCAE'}
                    className={claseFoto} />
                {/* </div> */}

                <Row className="infoSEyCAEDatos">
                    <Col xs={24} sm={24} md={24} lg={14} className="nombreSEyCAE">
                        <b key="nombre">Nombre:</b>
                        <br key="br"></br>
                        <label>{`${aspirante.apellidoPaterno} ${aspirante.apellidoMaterno} ${aspirante.nombre}`}</label>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={10} className="folioSEyCAE">
                        <b>Puesto:</b>
                        <br key="br"></br>
                        <label>{nombrePuesto}</label>
                    </Col>
                </Row>

                <Row className="infoSEyCAEDatos">
                    <Col xs={24} sm={24} md={24} lg={6} className="folioSEyCAE">
                        <b>Folio:</b>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} >
                                <label>{aspirante.folio}</label>
                            </Col>
                        </Row>
                    </Col>
                    <Col >
                        <div>
                            <b>Vigencia:</b>
                            <Col className="fechaInicioSEyCAE">
                                <label>{`${aspirante.fechaInicioVigencia} al ${aspirante.fechaFinVigencia}`}</label>
                            </Col>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Button
                        type='link'
                        onClick={handelOpenModal}
                        icon={<InfoCircleFilled style={{ color: '#9159CB' }} />}
                        style={{ fontSize: '9pt' }}

                    >
                        {`¿Cuál es mi función como ${aspirante.idPuesto === 1 ? 'SE' : 'CAE'}?`}
                    </Button>

                </Row>

            </Card>

            <ModalInfo tipoAspirante={tipoAspirante} funcionesAspirante={funcionesAspirante} isOpenModal={openModal} openModal={setOpenModal} />
        </>
    );

}
export default SEyCAE;
