import { Button, Col, Modal, Row, Image } from "antd"
import { useDataSelectStore, useUIStore } from "../hooks";
// import SECAEnoEncontrado from "../img/CAE_no_encontrado_4.svg";
import SECAEnoEncontrado from "../img/seYCaeNoEncontrado.svg"
import SECAEnoLabora from "../img/CAE_dejo_laborar_ine.svg";
import { useNavigate } from "react-router-dom";


export const ModalMessage = ({ tipoMensaje, mensaje }) => {
  const { closeModal, isModalOpen } = useUIStore();
  const { startCleanDataSelect } = useDataSelectStore();
  const navigate = useNavigate();

  const handleCancel = () => {
    startCleanDataSelect();
    closeModal();
    navigate('/');
  };

  return (
    <>
      <Modal
        open={isModalOpen}
        onCancel={handleCancel}
        width={370}
        centered
        footer
      >
        <div className="mensajeSEyCAE">
          <Row className="mensajeSEyCAEFoto">
            <Col xs={24} sm={24} md={24} lg={24} >
              <div>
                <Image
                  src={tipoMensaje === 1 ? SECAEnoEncontrado : SECAEnoLabora}
                  preview={false}
                  width={200}
                />
              </div>
              <div>
                <h3>SE y CAE no encontrado</h3>
                <p>{mensaje}</p>
              </div>
            </Col>
          </Row>
          <Row className="mensajeSEyCAEBoton">
            <Col xs={24} sm={24} md={24} lg={24} >
              <div >
                <Button type="primary" onClick={handleCancel}>Cerrar</Button>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  )
}