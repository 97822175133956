
import * as etiquetas from '../componentes/ApplicationResources';
import { useDataSelectStore } from '.';
import globalVars from '../utils/globalVars';
import { useState } from 'react';

export const useValidaEstructura = (json) => {

    const { startDataReadQR} = useDataSelectStore();
    const { result, setResult } = useState(true);



    try {
        let idProceso = 0
        let idDetalle = 0
        let idParticipacion = 0
        let idAspirante = 0
        let idAreaResponsabilidad = 0

        if (json != null) {
            let hash = json["hash"];
            let jsonA = { //Json del QR del GAFETE
                idProceso: json[etiquetas.keyIdProceso],
                idDetalle: json[etiquetas.keyIdDetalle],
                idParticipacion: json[etiquetas.keyIdParticipacion],
                idAspirante: json[etiquetas.keydAspirante]
            };
            let jsonB = {//Json del QR del la carta notificación
                idProceso: json[etiquetas.keyIdProceso],
                idDetalle: json[etiquetas.keyIdDetalle],
                idParticipacion: json[etiquetas.keyIdParticipacion],
                idAreaResponsabilidad: json[etiquetas.keyidAreaResponsabilidad]
            };
            const DataProceso = { ...jsonA, idAreaResponsabilidad: jsonB.idAreaResponsabilidad }
            startDataReadQR(DataProceso);
            let hashAct = crypto.createHash('MD5').update(JSON.stringify(jsonA)).digest('hex').toUpperCase();
            let hashActB = crypto.createHash('MD5').update(JSON.stringify(jsonB)).digest('hex').toUpperCase();

            if (hash === hashAct || hash === hashActB) {
                if (json.hasOwnProperty(etiquetas.keyIdProceso)) {
                    idProceso = json[etiquetas.keyIdProceso];
                }
                if (json.hasOwnProperty(etiquetas.keyIdDetalle)) {
                    idDetalle = json[etiquetas.keyIdDetalle];
                }
                if (json.hasOwnProperty(etiquetas.keyIdParticipacion)) {
                    idParticipacion = json[etiquetas.keyIdParticipacion];
                }
                if (json.hasOwnProperty(etiquetas.keydAspirante)) {
                    idAspirante = json[etiquetas.keydAspirante];
                }
                if (json.hasOwnProperty(etiquetas.keyidAreaResponsabilidad)) {
                    idAreaResponsabilidad = json[etiquetas.keyidAreaResponsabilidad];
                }

                if ((idProceso === undefined || idProceso + '' === '0')
                    && (idDetalle === undefined || idDetalle + '' === '0')
                    && (idParticipacion === undefined || idParticipacion + '' === '0')
                    && ((idAspirante === undefined || idAspirante + '' === '0') ||
                        (idAreaResponsabilidad === undefined || idAreaResponsabilidad + '' === '0'))) {
                    setResult(false);
                } else {
                    globalVars.uiText.cadena = true;
                }
            } else {
                console.log("Los hash son diferentes");
                setResult(false);
            }
        }
        setResult(true);
    } catch (error) {
        console.error("ocurrio un error al leer json " + error);
        setResult(false);
    }

    return {result}
}