
import { useEffect, useState } from "react";
import { Alert, Button, Col, Input, Row, Image, Form, Select, InputNumber } from "antd"
// import fotoINE from "../img/INE_2.png";
import fotoINE from "../img/Image_INE.png";
import funcionDeUnCAE from '../img/funcion_de_un_CAE.png';
import funcionDeUnSE from '../img/funcion_de_un_SE.png';
import * as etiquetas from './ApplicationResources';
import { useCatalogoStore, useConsultaSEyCAE, useDataSelectStore } from "../hooks";
import { ModalInfo } from "./ModalInfo";
import { useNavigate } from "react-router-dom";


export const FormBusquedaApirante = ({ isVerified }) => {

  const { Item } = Form;
  const { Option } = Select
  const [formSeCae] = Form.useForm();
  const { startLoadingProcesos, startLoadingEstadosByProceso, listaProcesos, listaEstados } = useCatalogoStore();
  const { startSelectProceso, startSelectEstado, startSelectSeccion } = useDataSelectStore();
  const { consultarSEyCAE } = useConsultaSEyCAE(isVerified);
  const [bloquearCampo, setBloquearcampo] = useState(false);
  const [tipoAspirante, setTipoAspirante] = useState('');
  const [funcionesAspirante, setFuncionesAspirante] = useState();
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    startLoadingProcesos(true)
  }, [])


  // const obtenerNombreDetalle = () => {
  //   let nombreProceso = "";
  //   listaProcesos.forEach((item, i) => {
  //     if (item.idDetalleProceso === detalle) {
  //       nombreProceso = item.descripcionDetalle;
  //     }
  //   });
  //   return nombreProceso;
  // }

  const onChangeProceso = (value, option) => {
    console.info("valor del proceso", value, option)
    let descProceso = (option !== undefined && option.descProceso) ?
      option.descProceso : "";
    startLoadingEstadosByProceso(value);
    startSelectProceso({ value, descProceso: descProceso });
  }

  const onChangeEntidad = (value) => {
    try {
      startSelectEstado(value);
    } catch (error) {
      console.error("onChangeEntidad " + error);
    }
  }

  const onSeccionSearch = (e) => {
    console.info("valor de la seccion", e);
    try {
      if (e !== undefined && e.currentTarget !== undefined && e.currentTarget.value !== undefined) {
        let seccion = e.currentTarget.value;
        if (seccion.length > 0) {
          validarYasignarSeccion(seccion)
        }
      }
    } catch (error) {
      console.error("onSeccionSearch " + error);
      startSelectSeccion(0);
    }
  }

  const validarYasignarSeccion = (seccion) => {
    console.info("valor de la seccion a asignar", seccion);
    let regex = /^[0-9]+$/;
    if (seccion !== undefined && seccion !== null) {
      //let valor = seccion.trim();
      let valorEntero = parseInt(seccion);
      if (regex.test(valorEntero)) {
        console.info("sección correcta");
        startSelectSeccion(seccion);
      }
    }
  }

  const onFinish = (values) => {
    console.info("onFinish");
    consultarSEyCAE();
    navigate('/informacionAspirante');
    formSeCae.resetFields();
  }

  const handelOpenModal = (tipoFuncion) => {
    if (tipoFuncion !== 'SE') {
      setTipoAspirante(tipoFuncion);
      setFuncionesAspirante(funcionDeUnCAE);
    } else {

      setTipoAspirante(tipoFuncion);
      setFuncionesAspirante(funcionDeUnSE);
    }

    setOpenModal(true);
  }


  return (
    <>
      <div key="busca">
        {/* <Row key="bienvenido" className="bienvenido">
          <Col xs={24} sm={24} md={24} lg={24}>
            <b>Te damos la bienvenida a "Conoce tu SE y CAE"</b>
          </Col>
        </Row> */}

        <Row key="busqueda" className="busqueda">
          <div className="contenido" >
            <Row >
              <Col xs={24} sm={24} md={17} lg={24} >
                <div className="container-title">
                  <h1 >
                    Consulta al personal del INE que te visitará en tu domicilio
                  </h1>
                </div>
                <div className="linea" style={{ backgroundColor: '#D992C8' }}></div>
                <div className="lineaPink"></div>
              </Col>
            </Row>
            <Form form={formSeCae}
              layout="vertical" scrollToFirstError
              onFinish={onFinish}
            >
              <Row>
                <Col xs={24} sm={24} md={8} lg={8}>
                  <Row className="camposFiltros">
                    <p className="labelObligatorio">
                      Los campos con (<span style={{ color: 'red' }}>*</span>)  son obligatorios
                    </p>
                    <Col xs={24} sm={24} md={20} lg={20} >
                      <Item
                        label={"Proceso electoral"}
                        name="proceso" className="label-form"
                        rules={[{
                          required: true,
                          message: "Selecciona un proceso"
                        }]}
                      >
                        <Select
                          placeholder="Selecciona un proceso"
                          onSelect={onChangeProceso}
                          disabled={bloquearCampo}
                        >
                          {listaProcesos &&
                            listaProcesos.map((item, index) => (
                              <Option key={index}
                                value={item.idDetalleProceso}
                                descProceso={item.descripcionDetalle}
                              >
                                {item.descripcionDetalle}
                              </Option>
                            )
                            )}
                        </Select>
                      </Item>
                      <Item label={"Entidad"}
                        name="estado" className="label-form"
                        required={true}
                        rules={[{
                          required: true,
                          message: "Selecciona una entidad"
                        }]}>
                        <Select
                          placeholder="Selecciona una entidad"
                          onSelect={onChangeEntidad}
                          disabled={bloquearCampo}>
                          {listaEstados &&
                            listaEstados.map((item, index) => (
                              <Option key={index}
                                value={item.idEstado}>
                                {item.nombreEstado}
                              </Option>
                            )
                            )}
                        </Select>
                      </Item>
                      <Row>
                        <Col xs={24} sm={24} md={24} lg={24} >
                          <Item label={"Sección electoral"}
                            name="seccion"
                            required={true}
                            className="label-form"
                            rules={[{
                              required: true,
                              message: "Selecciona la sección"
                            }]}>
                            <Input
                              placeholder="0000"
                              onChange={onSeccionSearch}
                              controls={false}
                              maxLength="4" min={1}
                              disabled={bloquearCampo}
                              id="inputSeccion" />
                          </Item>
                        </Col>
                      </Row>
                      <div className="buscaSEyCAEBoton" >
                        <Button type="primary"
                          htmlType="submit"
                          disabled={bloquearCampo}>
                          Consultar
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Col>

                <Col xs={24} sm={24} md={16} lg={16}>
                  <Row className="buscaSEyCAEFoto">

                    <div>
                      <p>Aquí encuentras tu sección electoral</p>
                      <Image src={fotoINE} style={{ height: '250px' }} id="imgCredencial" />
                    </div>
                  </Row>
                  <Row gutter={{
                    xs: 8,
                    sm: 16,
                    md: 24,
                    lg: 32,
                  }}>
                    <Col>
                      <Button style={{ color: '#D5007F' }} type="link" onClick={(tipoFuncion) => handelOpenModal(tipoFuncion = 'SE')}>¿Cuál es la función de un SE?</Button>
                    </Col>
                    <Col>
                      <Button style={{ color: '#D5007F' }} type="link" onClick={(tipoFuncion) => handelOpenModal(tipoFuncion = 'CAE')}>¿Cuál es la función de un CAE?</Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
            <Row className="buscaSEyCAEAviso">
              <div className="linea"></div>
              <Alert message={etiquetas.mensajeAviso} type="info" showIcon
                className="buscaSEyCAEAlert" />
            </Row>
          </div>
        </Row>
      </div>

      <ModalInfo
        tipoAspirante={tipoAspirante}
        funcionesAspirante={funcionesAspirante}
        isOpenModal={openModal}
        openModal={setOpenModal}
      />
    </>



  )
}
