
import { useDataSelectStore, useAspiranteStore } from "./";
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import { message } from 'antd';

export const useConsultaSEyCAE = (isVerified) => {
    const { datosProcesos } = useDataSelectStore();
    const { startLoadingAspirantebySeccion } = useAspiranteStore();


    const consultarSEyCAE = () => {
        console.log({ datosProcesos })
        try {

            // this.setState({
            //     cargando: true,
            //     idParticipacion: undefined,
            //     jsonAspirante: [],
            //     aspirante: []
            // });
            if (datosProcesos.idDetalleProceso === 'Selecciona un proceso' || datosProcesos.idDetalleProceso === undefined) {
                warning("Selecciona un proceso");
            } else if (datosProcesos.idEstado === 'Selecciona una entidad' || datosProcesos.idEstado === undefined) {
                warning("Selecciona una entidad");
            } else if (datosProcesos.seccion === undefined || datosProcesos.seccion === 0) {
                console.log(datosProcesos.seccion === undefined)
                warning("Ingresa una Sección electoral");
            } else {
                console.log("consultar servicio");
                if (isVerified) {
                    console.log("Entro a consultarSEyCAE");
                    ReactGA.event({
                        category: 'Consulta consultarSEyCAE',
                        action: `Consulta estado - seccion: ${datosProcesos.idEstado} - ${datosProcesos.seccion}`,
                    },
                        function () {
                            console.log(`Realiza consulta de consultarSEyCAE estado - seccion: ${datosProcesos.idEstado} - ${datosProcesos.seccion}`)
                        }
                    );
                    ReactGA4.event({
                        category: 'Consulta consultarSEyCAE',
                        action: `Consulta estado - seccion: ${datosProcesos.idEstado} - ${datosProcesos.seccion}`,
                    },
                        function () {
                            console.log(`Realiza consulta de consultarSEyCAE GA4 estado - seccion: ${datosProcesos.idEstado} - ${datosProcesos.seccion}`)
                        }
                    );
                    startLoadingAspirantebySeccion();
                    console.log("Salio de consultarSEyCAE");
                }

            }


        } catch (error) {
            console.log("consultarSEyCAE " + error);
            // setDataElectorales({...dataElectorales ,idParticipacion: 0});
            // setDataAspirante({aspirante:[]});
        }
    }


    const warning = (value) => {
        message.warning(value);
    };

    return {
        consultarSEyCAE
    }


}