import { Image, Modal } from "antd"
import cinta from '../img/cinta.png'

export const ModalInfo = ({ funcionesAspirante, tipoAspirante = "SE", isOpenModal, openModal }) => {
    const handleCancel = () => {
        openModal(false);
    }


    return (
        <>
            <Modal
                open={isOpenModal}
                onCancel={handleCancel}
                width={500}
                centered
                footer
                className="modal-info"
            >
                <Image src={cinta} preview={false} />
                <div style={{ textAlign: 'center' }}>
                    <h1 style={{ margin: 0 }}>{`¿Cuál es la función de un ${tipoAspirante}?`}</h1>
                    <Image src={funcionesAspirante} preview={false} />
                </div>

            </Modal>
        </>
    )
}