
/*Componentes */
import { ModalMessage } from '../componentes/ModalMessage';
import { FormBusquedaApirante } from "../componentes/FormBusquedaApirante";
import * as etiquetas from '../componentes/ApplicationResources';


/*Variable */
// const { Option } = Select;
// let recaptchaInstance;

export const BuscaSEyCAE = ({ isVerified }) => {


    return (
        <>
            <FormBusquedaApirante isVerified={isVerified} />


            <ModalMessage tipoMensaje={1} mensaje={etiquetas.mensajeNoEncontroSEyCAE} />
        </>

    );
}
