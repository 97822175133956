import { createSlice } from "@reduxjs/toolkit";

export const  catalogoSlice = createSlice({
    name:'catalogo',
    initialState:{
        listaProcesos: [],
        listaEstados: [],
        isLoadingProcesos: true
    },

    reducers:{
        onLoadingProcesos: (state, {payload = []}) => {
            state.isLoadingProcesos = false;
            state.listaProcesos = payload;
        },

        onLoadingEstados:(state, { payload  = []}) => {
            state.isLoadingProcesos = false
            state.listaEstados = payload;
        }
    }
}); 


export const {
    onLoadingProcesos,
    onLoadingEstados
} = catalogoSlice.actions; 