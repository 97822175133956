import { useEffect, useState } from "react";
import avatarNeutro from '../img/Avatar_neutro1.svg';
import avatarCAE from '../img/avatar_CAE_Hombre.svg';
import avatarCAEmujer from '../img/avatar_CAE_Mujer.svg';
import avatarSE_Hombre from '../img/avatar_SE_Hombre.svg';
import avatarSE_Mujer from '../img/avatar_SE_Mujer.svg';


export const usePuestoFotoAspirante = (aspirante) => {

    const [nombrePuesto, setNombrePuesto] = useState(avatarNeutro)
    const [fotoAspirante, setfotoAspirante] = useState("");
    const [claseFoto, setClaseFoto] = useState("");

    useEffect(() => {
        if (aspirante && aspirante !== null) {
            if (aspirante.nombrePuesto && aspirante.nombrePuesto !== '') {
                setNombrePuesto(aspirante.nombrePuesto.toUpperCase());
                if (aspirante.idPuesto && aspirante.idPuesto === 1) {
                    setNombrePuesto("Supervisor Electoral");
                } else if (aspirante.idPuesto && aspirante.idPuesto === 2) {
                    setNombrePuesto("Capacitador Electoral");
                }
            }
        }
    }, [aspirante])


    useEffect(() => {
        if (aspirante && aspirante !== null) {
            if (aspirante.foto && aspirante.foto !== null && aspirante.foto !== '' && aspirante.foto.length > 0) {
                //const ruta = (this.state.aspirante.foto).split(rutaFotos);
                console.log("Ruta foto: ", aspirante.foto);
                //fotoSubida = aspirante.foto; //ruta[1];
                setClaseFoto("fotoAspirante");
            }
            let existeFoto = aspirante.foto.length > 0 ? existeArchivo(aspirante.foto) : false;
            console.log("existeFoto" + existeFoto)
            if (existeFoto === true) {
                let xhr = new XMLHttpRequest();
                xhr.open('HEAD', aspirante.foto, false)
                xhr.send();
                const url = xhr.responseURL;

                const urlPruebas = url.includes('localhost:3000');

                if (urlPruebas) {
                    setfotoAspirante(`https://pru-conoceseycae-deceyec.ine.mx/${aspirante.foto}`);
                }
                else {
                    console.log({ urlFotoCompleta: url })
                    setfotoAspirante(url);
                }
                setClaseFoto("fotoAspirante");
            } else {
                if (aspirante.idPuesto !== undefined && aspirante.idPuesto === 1
                    && aspirante.sexo !== null && aspirante.sexo === 'M') {
                    setfotoAspirante(avatarSE_Mujer);
                    setClaseFoto("fotoAspirante");
                } else if (aspirante.idPuesto !== undefined && aspirante.idPuesto === 1
                    && aspirante.sexo !== null && aspirante.sexo === 'H') {
                    setfotoAspirante(avatarSE_Hombre);
                    setClaseFoto("fotoAspirante");
                }
                else if (aspirante.sexo !== null && aspirante.sexo === 'M') {
                    setfotoAspirante(avatarCAEmujer)
                    setClaseFoto("fotoAspirante");
                } else if (aspirante.sexo !== null && aspirante.sexo === 'H') {
                    setfotoAspirante(avatarCAE);
                    setClaseFoto("fotoAspirante");
                }
            }
        }
    }, [aspirante])

    const existeArchivo = (path) => {
        var xhr = new XMLHttpRequest();
        xhr.open('HEAD', path, false)
        xhr.send();
        if (xhr.status === 404) {
            console.log("false");
            return false;
        } else {
            console.log("true");
            return true;
        }
    };

    return {
        nombrePuesto,
        fotoAspirante,
        claseFoto
    }
}