import { Route, Routes } from "react-router-dom"
import LoadingScreen from 'react-loading-screen';
import { LoginApp, LoginLayaout } from "../componentes/LayoutApp"
import { Aspirantes } from "../pages"
import Layout, { Content, Footer, Header } from "antd/es/layout/layout"
import { Col, Row } from "antd"
import mainLogo from "../img/header_supycap_3.svg";
import footerLogo from '../img/ineLogoMD.jpg'
import loadingImage from '../img/loader.gif';
import { useUIStore } from "../hooks";
import { useEffect } from "react";

export const Navigate = () => {
    const { isLoading, notViewLoadingScreen } = useUIStore()
    useEffect(() => {
        setTimeout(() => {
            notViewLoadingScreen();
        }, 1000);

    }, []);

    return (
        <Layout>
            <LoadingScreen
                loading={isLoading}
                bgColor='#f1f1f1'
                spinnerColor='#9ee5f8'
                textColor='#676767' logoSrc={loadingImage}
            >

                <Content className="content ">
                    <div className="contentImage">
                        <Header >
                            <Row>
                                <img className="herader_img" src={mainLogo}
                                    width={220} height={27} alt='heder-img' />
                            </Row>
                        </Header>
                        <Routes>
                            <Route path="/:access" element={<LoginApp />} />
                            <Route path="/:proceso/:detalle/:participacionUrl/:aspiranteUrl" element={<LoginLayaout />} />
                            <Route path="/informacionAspirante" element={<Aspirantes />} />
                            <Route path="/" element={<LoginLayaout />} />
                        </Routes>
                        <Footer className={"footer"}>
                            <Row align={'middle'}>
                                <Col lg={{ span: 1 }} xs={{ span: 2 }} >
                                    <img className='logo-footer' src={footerLogo} alt="logo ine" />
                                </Col>
                                <Col lg={{ span: 12 }} xs={{ span: 12, offset: 2 }}>
                                    <h5 className="text-footer">© Derechos reservados, Instituto Nacional Electoral, México |
                                        Unidad Técnica de Servicios de Informática</h5>
                                </Col>
                            </Row>
                        </Footer>

                    </div>
                </Content>
            </LoadingScreen>

        </Layout >
    )
}
