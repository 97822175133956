import { createSlice } from "@reduxjs/toolkit";

export const aspiranteSlice = createSlice({
    name:'aspirante',
    initialState:{
        listaAspirantes:[],
        code:0,
        status:"",
        message:""
    },

    reducers:{
        onLoadingAspiranteBySeccion: (state, {payload}) => {
            const {listAsp, message, status, code} = payload;
            state.listaAspirantes = listAsp;
            state.message = message;
            state.status = status;
            state.code = code;
        }
        ,
        onLoadingAspirante: (state, {payload}) => {
            state.listaAspirantes = payload;
            // state.status = '200'
            // state.code = 200
            // state.message = ''
        },
        onCleanAspirantes:(state) => {
            state.listaAspirantes = [];
            state.code = 0;
            state.status = "";
            state.message ="";
        }
    }
    
});

export const {
    onLoadingAspiranteBySeccion,
    onLoadingAspirante,
    onCleanAspirantes
} = aspiranteSlice.actions;