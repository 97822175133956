import { createSlice } from "@reduxjs/toolkit";

export const uiSlice = createSlice(
    {
        name: 'ui',
        initialState: {
            isModalOpen: false,
            isLoading: true,
        },
        reducers: {
            onOpenModal: (state) => {
                state.isModalOpen = true;
            },
            onCloseModal: (state) => {
                state.isModalOpen = false;
            },
            onViewLoadingScreen :(state) => {
                state.isLoading = true;
            },
            onNotViewLoadingScreen :(state) => {
                state.isLoading = false;
            }
        }
    });

export const {
    onOpenModal,
    onCloseModal,
    onViewLoadingScreen,
    onNotViewLoadingScreen
} = uiSlice.actions;