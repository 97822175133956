import { createSlice } from "@reduxjs/toolkit";

export const dataSelectSlice = createSlice({
    name: 'dataSelect',
    initialState: {
        datosProcesos: {
            idProceso: 0,
            idDetalleProceso: 0,
            idEstado: 0,
            idParticipacion: 0,
            idAreaResponsabilidad: 0,
            descProceso: '',
            idAspirante: 0,
            seccion: 0
        }
    },

    reducers: {
        onSelectProceso: (state, { payload }) => {
            const { value, descProceso } = payload;
            console.log("onSelectProceso", value);
            state.datosProcesos = {
                ...state.datosProcesos,
                idDetalleProceso: value,
                descProceso
            }
        },
        onSelectEstado: (state, { payload }) => {
            state.datosProcesos = {
                ...state.datosProcesos,
                idEstado: payload
            }
        },
        onSelectSeccion: (state, { payload }) => {
            state.datosProcesos = {
                ...state.datosProcesos,
                seccion: payload
            }
        },
        onSelectIdAspirante: (state, { payload }) => {
            state.datosProcesos = {
                ...state.datosProcesos,
                idAspirante: payload
            }
        },
        onSelectIdParticipacion: (state, { payload }) => {
            state.datosProcesos = {
                ...state.datosProcesos,
                idParticipacion: payload
            }
        },

        onSetDataAspirante: (state, { payload }) => {
            const { idProceso, idDetalleProceso, idAspirante, idParticipacion, seccion, idEstado } = payload
            state.datosProcesos = {
                ...state.datosProcesos,
                idProceso,
                idDetalleProceso,
                idAspirante,
                idParticipacion,
                seccion,
                idEstado
            }
        },

        onCleanDataSelect: (state) => {
            state.datosProcesos = {
                idProceso: 0,
                idDetalleProceso: 0,
                idEstado: 0,
                idParticipacion: 0,
                idAreaResponsabilidad: 0,
                descProceso: '',
                idAspirante: 0,
                seccion: 0
            }
        },

        onDataReadQR: (state, { payload }) => {
            console.log({ payload })
            // state.datosProcesos({

            // });
        }

    }
});

export const {
    onSelectIdAspirante,
    onSelectIdParticipacion,
    onSelectProceso,
    onSelectEstado,
    onSelectSeccion,
    onSetDataAspirante,
    onCleanDataSelect,
    onDataReadQR
} = dataSelectSlice.actions
