let globalVars = {
    uiVars: {
		widthDD: "100%",
		scroll:300,
		totalId:999
	},
	AOS: {
		duration:700
	},
	uiText:{
		cadena:false,
		idProceso:0,
		idDetalle: 0,
		idParticipacion:0,
		nameProceso:'',
    }
};
export default globalVars;