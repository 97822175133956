/*react */
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';

/*antd */
import "antd";
/*Fontawesome*/
import "font-awesome/css/font-awesome.css";

/*Componentes */
import * as etiquetas from './ApplicationResources';
import "../css/App.css";
import App from './App';
import { descifrarCadena } from "../helpers/decifrarCadena";
import { useValidaEstructura} from "../hooks/useValidaEstructura";
import { Navigate } from "../router/Navigate";


export const LayoutApp = () => {
    ReactGA.initialize(etiquetas.idSeguimiento); //primera carga
    ReactGA4.initialize(etiquetas.idMedición); //primera carga
    ReactGA.pageview(window.location.pathname + window.location.search); //numero de visitas
    console.log("ReactGA " + ReactGA);
    return (
        <Navigate/>
    );
}

export const LoginLayaout = () => {
    return (
        <App cargando={false} />
    );
}

export const LoginApp = () => {

    const { isDecoded, jsonAspirante} = descifrarCadena();
    const {result} = useValidaEstructura(jsonAspirante)

    const defineAccess = () => {
        if (isDecoded) {
            if (result) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    if (defineAccess()) {
        return (
            <App cargando={false} />
        );
    }
    else {
        //<NoMostrar></NoMostrar>
        return (<App cargando={false} />);
    }

}

export default LayoutApp;
