
import MostrarseyCae from './SEyCAE';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { useAspiranteStore } from "../hooks";


const CarruselSECAE = ({ jsonAspirante }) => {

    const { listaAspirantes } = useAspiranteStore()

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };



    return (

        <Carousel
            responsive={responsive}
            additionalTransfrom={0}
            arrows
            autoPlaySpeed={3000}
            centerMode={false}
            className=""
            containerClass="container-with-dots"
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            pauseOnHover
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            rewind={false}
            rewindWithAnimation={false}
            rtl={false}
            shouldResetAutoplay
            showDots={false}
            sliderClass=""
            slidesToSlide={1}
            swipeable
        >
            {
                listaAspirantes.map(aspirante => (
                    <div>
                        <div style={{
                            display: "flex",
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <MostrarseyCae
                                aspirante={aspirante}
                                idAspirante='unico' />
                        </div>

                    </div>
                ))

            }
        </Carousel>

    );
}
export default CarruselSECAE;