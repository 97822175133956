import { configureStore } from "@reduxjs/toolkit";
import { aspiranteSlice, catalogoSlice, dataSelectSlice, uiSlice } from "./slices";

export const store = configureStore({
    reducer:{
        catalogo: catalogoSlice.reducer,
        dataSelect: dataSelectSlice.reducer,
        aspirante: aspiranteSlice.reducer,
        ui: uiSlice.reducer
    }
});