
/*Componentes */
import { useEffect } from 'react';
import Template from './Template';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { useParams } from "react-router-dom";
import { useDataSelectStore } from '../hooks';
//lleva el correo de sed
const CAP_KEY = "6Ld0dTMaAAAAAPrfn9VJZmGjrxcxngTYr1ZawdpT"

//lleva el correo de ssce.app@gmail.com pass  uts1ssce
//const CAP_KEY = "6LcJwCAeAAAAADUloJLETpVgucq06sY7WolHp159"
//let recaptchaInstance;

const Container = ({ cargando }) => {
    const { proceso, detalle, participacionUrl, aspiranteUrl } = useParams();
    const { setDataAspirante } = useDataSelectStore();

    const isVerified = true;
    // const captcha = true;

    useEffect(() => {
        if (proceso > 0) {
            setDataAspirante({
                proceso: Number(proceso),
                idDetalleProceso: Number(detalle),
                idParticipacion: Number(participacionUrl),
                idAspirante: Number(aspiranteUrl),
                seccion: Number(participacionUrl),
                idEstado: 1
            })
        }


    }, [])


    return (
        <GoogleReCaptchaProvider
            reCaptchaKey={CAP_KEY}
            language="es-419" >
            <div>
                <Template
                    cargando={cargando}
                    isVerified={isVerified}
                />
            </div>
        </GoogleReCaptchaProvider>


    );
}
export default Container;
