import { useDispatch, useSelector } from "react-redux"
import { onCloseModal, onNotViewLoadingScreen, onOpenModal, onViewLoadingScreen } from "../store/slices/uiSlice";

export const useUIStore = () => {
    const dispatch = useDispatch();
    const {isModalOpen, isLoading} = useSelector(state => state.ui);

    const openModal = () => {
        dispatch(onOpenModal());
    }

    const closeModal = () => {
        dispatch(onCloseModal());
    }

    const viewLoadingScreen = () => {
        dispatch(onViewLoadingScreen());
    }
    const notViewLoadingScreen = () => {
        dispatch(onNotViewLoadingScreen());
    }

    return {
        //* Propiedades
        isModalOpen,
        isLoading,
        //* funciones
        openModal,
        closeModal,
        viewLoadingScreen,
        notViewLoadingScreen
    }
}