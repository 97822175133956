import { useDispatch, useSelector } from "react-redux"
import {
    onDataReadQR, onSelectEstado, onSelectProceso, onSelectSeccion,
    onSelectIdParticipacion, onSelectIdAspirante, onSetDataAspirante, onCleanDataSelect
} from "../store/slices/dataSelectSlice"

export const useDataSelectStore = () => {

    const { datosProcesos } = useSelector(state => state.dataSelect);

    const dispatch = useDispatch();

    const startSelectProceso = (procesoSelec) => {
        dispatch(onSelectProceso(procesoSelec));
    }
    const startSelectEstado = (idEstado) => {
        dispatch(onSelectEstado(idEstado));
    }
    const startSelectSeccion = (seccion) => {
        dispatch(onSelectSeccion(seccion));
    }

    const startSelectIdAspirante = (idAspirante) => {
        dispatch(onSelectIdAspirante(idAspirante));
    }

    const startSelectIdParticipacion = (idParticipacion) => {
        dispatch(onSelectIdParticipacion(idParticipacion));
    }

    const setDataAspirante = (data) => {
        dispatch(onSetDataAspirante(data))
    }

    const startCleanDataSelect = () => {
        dispatch(onCleanDataSelect());
    }

    const startDataReadQR = ({ dataQR = {} }) => {
        dispatch(onDataReadQR(dataQR))

    }
    return {
        //Propiedades
        datosProcesos,
        //Metodos
        startSelectIdParticipacion,
        startSelectIdAspirante,
        startSelectProceso,
        startSelectEstado,
        startSelectSeccion,
        setDataAspirante,
        startDataReadQR,
        startCleanDataSelect
    }
}
