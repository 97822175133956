/*---------------------------------------------------------------------------------
                                ETIQUETAS Y MENSAJES
 Contiene las etiquetas y mensajes generales del sistema y de cada módulo
---------------------------------------------------------------------------------*/
export const title_sistema = "Reclutamiento y Seguimiento a Supervisores y Capacitadores-Asistentes";
export const derechosReservador = "Derechos Reservados, Instituto Nacional Electoral, México I Unidad Técnica de Servicios de Informática.";
export const versionSistema = "Versión del sistema 1.0";
export const keyIdProceso = "idProceso";
export const keyIdDetalle = "idDetalle";
export const keyIdParticipacion = "idParticipacion";
export const keydAspirante = "idAspirante";
export const keyidAreaResponsabilidad = "idAreaResponsabilidad";
export const mensajeAspirante = "No se puede mostrar la información, inténtalo más tarde.";
export const mensajeSinAspirante = "No se encontraron resultados.";
export const mensajeNoEncontroSEyCAE = "No se encontraron datos de SE y CAE para esta sección";
export const mensajeSEyCAENoLaboraINE = "El SE o CAE dejo de laborar en el instituto :(";
export const mensajeAviso = "La información puede variar debido a rotación del personal. Consulta periódicamente para contar con información actualizada";
//export const rutaFotos = "/2021SistDECEYEC/supycapv9"//Local
export const rutaFotos = "/Glusterfs"//"/SistDECEYEC/supycapv9" //Pruebas
//*IDS Google Analitics y codigo de seguimiento 2022-2023
// export const idSeguimiento = "UA-238357134-19";
// export const idMedición = "G-PK3XFJQTBB";
//*IDS Google Analitics y codigo de seguimiento 2023-2024
export const idSeguimiento = "UA-268769028-7 "
export const idMedición = "G-T87TQ5GHWK ";

/* export const procesos = [
    {
        "idProcesoElectoral": 23,
        "idDetalleProceso": 206,
        "descripcionDetalle": "PEL-COAH-2023",
        "listaEstados": [
            {
                "idEstado": 5,
                "nombreEstado": "COAHUILA",
                "listaDistritos": null
            }
        ]
    },
    {
        "idProcesoElectoral": 23,
        "idDetalleProceso": 207,
        "descripcionDetalle": "PEL-MEX-2023",
        "listaEstados": [
            {
                "idEstado": 15,
                "nombreEstado": "MÉXICO",
                "listaDistritos": null
            }
        ]
    }
] */