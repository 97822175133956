import React from 'react';
import * as ReactDOM from 'react-dom/client';
import LayoutApp from './componentes/LayoutApp';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import {store} from './store'

const container = document.getElementById('root');

const root = ReactDOM.createRoot(container);

root.render(
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <LayoutApp/>
        </BrowserRouter>
      </Provider>
     </React.StrictMode>
  );
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
